import React from 'react';
import {
	composeMiddleware,
	makeActionTypes,
	makeEmptyActionCreator,
	makePayloadActionCreator,
	makeReducer,
} from '@redux-tools/react';
import { getToken, isTokenExpired, refreshToken } from '@uamk/domain-auth';
import { addNotification, type } from '@uamk/notifications';
import { makeMiddleware, typeEq } from '@uamk/utils';
import { Client } from '@stomp/stompjs';

import { FormattedMessage } from 'react-intl';

import { DEBUG, IS_MOBILE_APP } from './constants';
import m from './messages';

const ActionTypes = makeActionTypes('@websocket', [
	'CONNECT',
	'SUBSCRIBE',
	'UNSUBSCRIBE_ALL',
	'PUBLISH',
	'DISCONNECT',
	'ERROR',
	'FORCE_REFETCH',
	'SET_ACTIVE',
]);

export const connect = makeEmptyActionCreator(ActionTypes.CONNECT);
export const subscribe = makePayloadActionCreator(ActionTypes.SUBSCRIBE);
export const unsubscribeAll = makeEmptyActionCreator(ActionTypes.UNSUBSCRIBE_ALL);
export const publish = makePayloadActionCreator(ActionTypes.PUBLISH);
export const disconnect = makeEmptyActionCreator(ActionTypes.DISCONNECT);
export const error = makeEmptyActionCreator(ActionTypes.ERROR);
export const forceRefetch = makePayloadActionCreator(ActionTypes.FORCE_REFETCH);
export const setActive = makePayloadActionCreator(ActionTypes.SET_ACTIVE);

export const shouldForceRefetch = (state) => state.websockets?.forceRefetch;
export const isWsActive = (state) => state.websockets?.active;

export const makeWebsocketMiddleware = ({ brokerURL }) => {
	let store;
	let reconnectTries = 0;

	const subscriptions = new Set();
	const waitingSubscriptions = new Set();

	const onError = () => {
		if (reconnectTries >= 10) {
			store.dispatch(error());
			reconnectTries = 0;
		} else {
			reconnectTries++;
		}
	};

	const setConnectionActive = (value) => store.dispatch(setActive(value));

	const client = new Client({
		// eslint-disable-next-line no-console
		...(DEBUG ? { debug: (message) => console.log('STOMP:', message) } : {}),
		reconnectDelay: 1000,
		heartbeatIncoming: 4000,
		heartbeatOutgoing: 4000,
		forceBinaryWSFrames: true,
		appendMissingNULLonIncoming: true,
		beforeConnect: async () => {
			// When app goes from switched off screen to foreground it reconnects WSS
			// which is automatically closed when app goes from foreground to switched of screen.
			// In such case store has false information about active websocket, so we need to set it to false
			if (isWsActive(store.getState())) {
				setConnectionActive(false);
			}
			const token = getToken(store.getState());

			if (token && isTokenExpired(token)) {
				store.dispatch(refreshToken());
			}
		},
		onConnect: () => {
			waitingSubscriptions.forEach(({ path, callback }) => {
				const subscription = client.subscribe(path, callback);
				subscriptions.add(subscription);
			});
			waitingSubscriptions.clear();
			reconnectTries = 0;
			setConnectionActive(true);
		},
		onDisconnect: () => {
			subscriptions.forEach((subscription) => subscription.unsubscribe());
			subscriptions.clear();
			reconnectTries = 0;
			setConnectionActive(false);
		},
		onStompError: onError,
		onWebSocketError: onError,
		onWebSocketClose: onError,
	});

	const connectMiddleware = makeMiddleware(
		typeEq(ActionTypes.CONNECT),
		({ getState }) => async () => {
			if (!client.connected && !client.active) {
				const token = getToken(getState());
				if (token && !isTokenExpired(token)) {
					client.configure({
						connectHeaders: {
							Authorization: `Bearer ${token}`,
						},
						brokerURL,
					});
					client.activate();
				}
			}
		}
	);

	const subscribeMiddleware = makeMiddleware(
		typeEq(ActionTypes.SUBSCRIBE),
		() => async ({ payload: { path, callback } }) => {
			if (!client.connected) {
				waitingSubscriptions.add({ path, callback });
			} else {
				const subscription = client.subscribe(path, callback);
				subscriptions.add(subscription);
			}
		}
	);

	const unsubscribeAllMiddleware = makeMiddleware(typeEq(ActionTypes.UNSUBSCRIBE_ALL), () => () => {
		subscriptions.forEach((subscription) => subscription.unsubscribe());
		subscriptions.clear();
	});

	const publishMiddleware = makeMiddleware(
		typeEq(ActionTypes.PUBLISH),
		() => async ({ payload: { path, body } }) => {
			if (client.connected) {
				client.publish({
					destination: path,
					body: JSON.stringify(body),
				});
			}
		}
	);

	const errorMiddleware = makeMiddleware(typeEq(ActionTypes.ERROR), ({ dispatch }) => () => {
		dispatch(
			addNotification({
				message: (
					<FormattedMessage {...(IS_MOBILE_APP ? m.connectionErrorMobile : m.connectionErrorWeb)} />
				),
				type: type.ERROR,
				showCloseBtn: false,
				labelAction: <FormattedMessage {...m.closeBtn} />,
				hideAfter: 0,
			})
		);
		dispatch(disconnect());
	});

	const disconnectMiddleware = makeMiddleware(typeEq(ActionTypes.DISCONNECT), () => () => {
		if (client.active) {
			client.deactivate();
		}
	});

	const middleware = composeMiddleware(
		connectMiddleware,
		subscribeMiddleware,
		unsubscribeAllMiddleware,
		publishMiddleware,
		disconnectMiddleware,
		errorMiddleware
	);

	middleware.initialize = (storeInstance) => {
		// TODO think about better way how to pass store
		store = storeInstance;
	};

	return middleware;
};

const initialState = { forceRefetch: false, active: false };

export const websocketsReducer = makeReducer(
	[
		[ActionTypes.FORCE_REFETCH, (state, { payload }) => ({ ...state, forceRefetch: payload })],
		[ActionTypes.SET_ACTIVE, (state, { payload }) => ({ ...state, active: payload })],
	],
	initialState
);
