import { defineMessages } from 'react-intl';

export default defineMessages({
	notifications: {
		id: 'ui.notifications',
		defaultMessage: 'Notifications',
	},
	logout: {
		id: 'ui.logout',
		defaultMessage: 'Logout',
	},
	menu: {
		id: 'ui.menu',
		defaultMessage: 'Menu',
	},
	back: {
		id: 'ui.back',
		defaultMessage: 'Back',
	},
	next: {
		id: 'ui.next',
		defaultMessage: 'Next',
	},
	step: {
		id: 'ui.step',
		defaultMessage: 'Step',
	},
	copyright: {
		id: 'ui.copyright',
		defaultMessage: '© {year} ÚAMK. All rights reserved. +420 261 104 550 digiasist@uamk.cz',
	},
	perPage: {
		id: 'ui.perPage',
		defaultMessage: 'per page',
	},
	entries: {
		id: 'ui.entries',
		defaultMessage: 'Total {pageSize} from {totalRecords}',
	},
	filter: {
		id: 'ui.filter',
		defaultMessage: 'Filter',
	},
	selectAll: {
		id: 'select.selectAll',
		defaultMessage: 'All',
	},
	multiSelectAll: {
		id: 'multiselect.all',
		defaultMessage: 'All',
	},
	multiSelectManySelected: {
		id: 'multiselect.manySelected',
		defaultMessage: 'Selected',
	},
	dropzoneTitle: {
		id: 'ui.dropzoneTitle',
		defaultMessage: 'Files drop here, or ',
	},
	dropzoneTitleLink: {
		id: 'ui.dropzoneTitleLink',
		defaultMessage: 'chose file',
	},
	dropzoneSubtitle: {
		id: 'ui.dropzoneSubtitle',
		defaultMessage: 'Upload max {maxFiles},',
	},
	dropzoneSupportedFileTypes: {
		id: 'ui.dropzoneSupportedFileTypes',
		defaultMessage: 'supported file types:',
	},
	dropzoneMaxSizeDescription: {
		id: 'ui.dropzoneMaxSizeDescription',
		defaultMessage: 'Maximum file size: {maxSize} MB',
	},
	dropzoneTooLarge: {
		id: 'ui.dropzoneTooLarge',
		defaultMessage: 'The file {file} failed to compress. Please upload another file!',
	},
	validationRequired: {
		id: 'validation.required',
		defaultMessage: 'This field is required',
	},
	cellActive: {
		id: 'table.cellActive',
		defaultMessage: 'Active',
	},
	cellInactive: {
		id: 'table.cellInactive',
		defaultMessage: 'Inactive',
	},
	yesLabel: {
		id: 'YesNoRadio.yesLabel',
		defaultMessage: 'Yes',
	},
	noLabel: {
		id: 'YesNoRadio.noLabel',
		defaultMessage: 'No',
	},
	unspecifiedLabel: {
		id: 'YesNoRadio.unspecifiedLabel',
		defaultMessage: 'Not Specified',
	},
	confirmModalTitle: {
		id: 'confirmModal.confirmModalTitle',
		defaultMessage: 'Do you really want to leave?',
	},
	confirmModalSubtitle: {
		id: 'confirmModal.confirmModalSubtitle',
		defaultMessage: 'You have unsaved changes for an {label}. If you leave, you will lose them.',
	},
	confirmModalLeaveLabel: {
		id: 'confirmModal.confirmModalLeaveLabel',
		defaultMessage: 'Leave without saving',
	},
	confirmModalStayLabel: {
		id: 'confirmModal.confirmModalStayLabel',
		defaultMessage: 'Stay on the page',
	},
	loading: {
		id: 'ui.loading',
		defaultMessage: 'Loading',
	},
	todaysDate: {
		id: 'format.todaysDate',
		defaultMessage: 'Today {time}',
	},
});
