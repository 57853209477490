import { defineMessages } from 'react-intl';

export default defineMessages({
	connectionErrorWeb: {
		id: 'websocket.connectionErrorWeb',
		defaultMessage: 'New incident feed not established.',
	},
	connectionErrorMobile: {
		id: 'websocket.connectionErrorMobile',
		defaultMessage: 'Websocket connection failed.',
	},
	closeBtn: {
		id: 'websocket.closeBtn',
		defaultMessage: 'Close',
	},
});
