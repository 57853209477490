import { Apps, ClientIds } from './commonConstants';

export * from './commonConstants';

export const defaultAuthAPIParams = {
	client_id: ClientIds.PARTNER_PORTAL,
	client_secret: 'noonewilleverguess',
};

export const baseUrl = process.env.BASE_API_URL || '';

export const APP = Apps.PARTNER_PORTAL;
