import { Czech } from 'flatpickr/dist/l10n/cs';

export const Locale = {
	...Czech,
	rangeSeparator: ' - ',
};

export const DatepickerOptions = {
	allowInput: true,
	wrap: true,
	disableMobile: true,
	locale: Locale,
	dateFormat: 'd. m. Y',
	minDate: '',
	maxDate: 'today',
};
