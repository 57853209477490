export const jwtAttributes = [
	'user_name',
	'exp',
	'email',
	'authorities',
	'partnerId',
	'id',
	'partnerName',
];

export const Roles = {
	ADMIN: 'ADMIN',
	CLIENT: 'CLIENT',
	CHANGE_PASSWORD_REQUIRED: 'CHANGE_PASSWORD_REQUIRED',
	DISPATCHER: 'DISPATCHER',
	DRIVER: 'DRIVER',
};

export const Apps = {
	PARTNER_PORTAL: 'partner-portal',
	PARTNER_APP: 'partner-app',
	CLIENT_APP: 'client-app',
};

export const ClientIds = {
	PARTNER_PORTAL: 'uamk-portal',
	PARTNER_APP: 'uamk-partner-app',
	CLIENT_APP: 'uamk-client-app',
};
